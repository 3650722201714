import React from "react";
import { Project } from "../../../config/models";
import SimpleDatePicker from "../../simpleDatePicker/SimpleDatePicker";

interface EditProjectModalProps {
  isCreate?: boolean;
  onClose: (project?: Project) => void;
  project?: Project;
  open: boolean;
}

interface EditProjectModalState {
  project?: Project;
  loading: boolean;
  startDatePickerOpen: boolean;
  endDatePickerOpen: boolean;
  selectedTemplate: string;
  showPreview: boolean;
}

// 양식 미리보기 이미지 경로 정의 수정에정
const TEMPLATE_PREVIEWS = {
  건축: "/images/templates/architecture-template.png",
  일반: "/images/templates/general-template.png",
};

export default class EditProjectModal extends React.Component<
  EditProjectModalProps,
  EditProjectModalState
> {
  constructor(props: EditProjectModalProps) {
    super(props);
    this.state = {
      loading: false,
      project: this.props.project,
      startDatePickerOpen: false,
      endDatePickerOpen: false,
      selectedTemplate: this.props.project?.template || "일반",
      showPreview: false,
    };
  }

  handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    this.setState((prevState: any) => ({
      project: {
        ...prevState.project,
        [name]: value,
      },
    }));

    // 양식 선택 시 해당 템플릿 선택
    if (name === "template") {
      this.setState({ selectedTemplate: value });
    }
  };

  componentDidUpdate(prevProps: EditProjectModalProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        project: this.props.project,
        selectedTemplate: this.props.project?.template || "일반",
        showPreview: false,
      });
    }
  }

  togglePreview = () => {
    this.setState((prevState) => ({
      showPreview: !prevState.showPreview,
    }));
  };

  render() {
    const { open, onClose } = this.props;
    const { project, selectedTemplate, showPreview } = this.state;

    return (
      <div
        className={open ? "modal fade show" : "modal fade"}
        data-bs-backdrop="static"
        id="project-editModal"
        tabIndex={-1}
        aria-labelledby="project-editLabel"
        style={open ? { display: "block" } : { display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="project-editLabel">
                프로젝트 {this.props.isCreate ? "추가" : "수정"}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => onClose()}
              ></button>
            </div>
            <div className="modal-body">
              <div className="input-content">
                <div className="input-item">
                  <div className="title">프로젝트 이름</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      aria-label="project-name"
                      aria-describedby="basic-addon1"
                      name="name"
                      value={project?.name || ""}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div className="title">공종</div>
                  <div className="contents">
                    <select
                      className="form-select form-select-lg"
                      name="corporationType"
                      value={project?.corporationType || ""}
                      onChange={this.handleInputChange}
                    >
                      <option value="" disabled hidden>
                        업체 정보를 선택해주세요.
                      </option>
                      {[
                        "건축",
                        "토목",
                        "조경",
                        "실내건축",
                        "전기",
                        "소방",
                        "통신",
                        "기계설비",
                        "상하수도",
                        "포장",
                        "석공",
                        "비계,구조물해체",
                        "금속구조물, 창호",
                        "철근, 콘크리트",
                        "기타",
                      ].map((corporationType: string) => (
                        <option key={corporationType} value={corporationType}>
                          {corporationType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* 방법 1: 라디오 버튼과 미리보기 이미지 */}
                <div className="input-item">
                  <div className="title">양식</div>
                  <div className="contents">
                    <div className="template-selection">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="template"
                          id="template-general"
                          value="일반"
                          checked={selectedTemplate === "일반"}
                          onChange={this.handleInputChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="template-general"
                        >
                          일반
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="template"
                          id="template-architecture"
                          value="건축"
                          checked={selectedTemplate === "건축"}
                          onChange={this.handleInputChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="template-architecture"
                        >
                          건축
                        </label>
                      </div>

                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary ms-2"
                        onClick={this.togglePreview}
                      >
                        {showPreview ? "미리보기 닫기" : "미리보기 보기"}
                      </button>
                    </div>

                    {/* 방법 3: 모달 내 탭 형식의 미리보기 */}
                    {showPreview && (
                      <div className="template-tabs mt-3">
                        <div className="tab-content p-2 border rounded">
                          <div
                            className={`tab-pane fade ${
                              selectedTemplate === "건축" ? "show active" : ""
                            }`}
                          >
                            <div className="d-flex">
                              <div className="template-thumbnail me-3">
                                <img
                                  src={TEMPLATE_PREVIEWS["건축"]}
                                  alt="건축 양식 미리보기"
                                  className="img-thumbnail"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div className="template-description">
                                <h6>건축 양식</h6>
                                <p className="small text-muted">
                                  건축 프로젝트에 최적화된 양식으로, 건축 관련
                                  특수 필드와 레이아웃을 제공합니다.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`tab-pane fade ${
                              selectedTemplate === "일반" ? "show active" : ""
                            }`}
                          >
                            <div className="d-flex">
                              <div className="template-thumbnail me-3">
                                <img
                                  src={TEMPLATE_PREVIEWS["일반"]}
                                  alt="일반 양식 미리보기"
                                  className="img-thumbnail"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div className="template-description">
                                <h6>일반 양식</h6>
                                <p className="small text-muted">
                                  모든 유형의 프로젝트에 사용할 수 있는 범용
                                  양식입니다.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="input-item">
                  <div className="title">설명</div>
                  <div className="contents">
                    <textarea
                      className="form-control ui-resizable-disabled"
                      style={{ resize: "none" }}
                      aria-label="With textarea"
                      name="memo"
                      value={project?.memo || ""}
                      onChange={this.handleInputChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="modal-footer-wrap">
                <button
                  type="button"
                  className="btn btn-light btn-lg flex-1"
                  data-bs-dismiss="modal"
                  onClick={() => onClose()}
                >
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-lg flex-2"
                  data-bs-dismiss="modal"
                  onClick={() => onClose(project)}
                >
                  <span className="material-symbols-outlined me-2">
                    {this.props.isCreate ? "add" : "done"}
                  </span>
                  {this.props.isCreate ? "확인" : "저장하기"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
